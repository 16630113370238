// ContactUs.js
import React from 'react';
import { Container } from 'react-bootstrap';

function ContactUs() {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <h4>Contact Us</h4>
      <p>No. 197, 4th floor , Sri Rama Road, Thyagarajanagar, Bengaluru - 560070</p>
      <p>Ph No. 9738106569</p>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.614772197195!2d77.56773377454596!3d12.932463015736243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1571e8363259%3A0x9feadd23bb26ef0b!2s197%2C%20Sri%20Rama%20Rd%2C%202nd%20Block%2C%20Thyagaraja%20Nagar%2C%20Banashankari%2C%20Bengaluru%2C%20Karnataka%20560070!5e0!3m2!1sen!2sin!4v1719316492229!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  );
};

export default ContactUs;
